:root {
  --primary-color: #1a9c38;
  --secondary-color: #ef7b24;
  --press-color: #b5b4ff;
  --bg-color: #fcfcfc;
  --typo-color: #1c1612;
  --primary-color-hover:#48B060;
  --primary-color-press:#76C488;
  --primary-color-inactif:#A4A2A0;
  --danger-color:#F14B26;

}
a{
  cursor: pointer;
}
@font-face {
  font-family: "DM-Sans-Black";
  src: url("./assets/fonts/DMSans-Black.ttf");
}
@font-face {
  font-family: "DM-Sans-Bold";
  src: url("./assets/fonts/DMSans-Bold.ttf");
}
@font-face {
  font-family: "DM-Sans-SemiBold";
  src: url("./assets/fonts/DMSans-SemiBold.ttf");
}
@font-face {
  font-family: "DM-Sans-Medium";
  src: url("./assets/fonts/DMSans-Medium.ttf");
}
@font-face {
  font-family: "DM-Sans-Regular";
  src: url("./assets/fonts/DMSans-Regular.ttf");
}
h1 {
  font-family: "DM-Sans-Black" !important;
  font-size: 42px !important;
  font-weight: 900 !important ;
  line-height: 55px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}
h2 {
  font-family: "DM-Sans-Bold" !important;
  font-size: 38px !important;
  font-weight: 700 !important;
  line-height: 49px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}
h3 {
  font-family: "DM-Sans-Bold" !important;
  font-size: 32px !important;
  font-weight: 700 !important;
  line-height: 42px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}
h4 {
  font-family:"DM-Sans-SemiBold" !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 31px !important;
  letter-spacing: 0em !important;
  text-align: left !important; 
}
.right{
  float: right;
}
input[readonly], .ql-disabled {
  background-color: #f0f0f0; /* Set your desired background color */
  /* Add any additional styles you need */
}

