
.bg-sideBar{
    background-color: #fff;
  }
  .nav-item.active{
    padding: 12px 8px;
  }
  .nav-item{
    margin:10px 10px 10px 23px;
    padding: 12px 8px;
    text-decoration: none;
    color: var(--typo-color);
    font-family: 'DM-Sans-Medium';
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    line-height: 24px;
    background-color: #fff;
    width: 90%;
  }
  .ml-15{
    margin-right: 12px;
  }
  .nav{
    width: 100%;
  }
  .nav-item.active{
    background-color: var(--primary-color);
    width: 90%; 
    padding: 12px 8px;
    border-radius: 8px;
   
  }
  .nav-item.active svg{
    color: #fff;
  }
  .pl-30{
    padding-left: 30px;
  }
  
  .nav-item.active span{
    color:#fff;
  }
  .shadow-sidebar{

    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10) !important;
  }
  .px-30 {
        padding: 19px 30px;
  }
  .nav-item h5{
    font-family: "Futura";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
  .dark-txt{

font-family: "Futura";
font-size: 26px;
font-weight: 500;
line-height: 32px;
letter-spacing: -0.02em;
text-align: left;
color:#2B2B2B;
padding: 19px 30px;

  }
 .px-30{
    padding-left: 30px;
 }
 /* .logo{
    padding-top: 19px;
    padding-left: 30px;
    padding-bottom: 39px;
 } */
/* .nav-img{
    width:20px;
} */
.close-sidebar{
  flex: 0 1 !important;
  width: auto !important;
}
/* .none{
  display: none !important;
} */
.logout{
  position: fixed;
  bottom: 10%;
}
.footer-sidebar{
    /* position: absolute;
    bottom: 60px; */
}

@media screen and (max-width:1024px) {
  .nav-item {
    margin: 5px !important;
    padding: 5px !important;
    text-decoration: none;
    color: var(--typo-color);
    font-family: 'DM-Sans-Medium';
    font-size: 12px  !important;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    line-height: 15px !important;
    background-color: #fff;
    width: 90%;
}
  .logo{
    width: 50%;
  }

  .ml-15{
    margin-right: 2px !important;
  }
  .nav-item.active{
    background-color: var(--primary-color);
    width: 95% !important; 
    border-radius: 0px 4px 4px 0px;
  }
  .cta{
    font-size: 12px !important;
  }
  .footer-sidebar label{
    font-size: 12px !important;
  }
  .logo{
    width:75%;
  }
}
@media screen and (max-width:1286px){

  .logo{
    width: 75%;
  }
}