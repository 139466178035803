.left{
    background-color: #fff;
    margin: 0px !important;
    
}
.right{
    background-color: #FCFCFC;
    min-height: 100vh;
    position: relative;
}
.form-login{
    margin: auto;
    padding: 100px;
    width: 70%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
 
}

.password-field {
    display: flex;
    flex-direction: column;
 
  }
  
  .password-input-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .password-input-container input {
    flex-grow: 1;
  }
  
  .password-input-container .password-icon {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    cursor: pointer;
  }
  @media screen and (max-width:768px) {
    .form-login{
      width: 100%;
    }
  }