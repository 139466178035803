.btn-primary-al{
    background-color: var(--primary-color);
    color: #fff;
    padding: 10px 11px ;
    border-radius: 8px;
    border: 2px solid var(--primary-color) !important;
    font-family:"DM-Sans-Regular";
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    text-decoration:none !important;
}
.btn-danger-al{
    background-color: var(--danger-color);
    color: #fff;
    padding: 10px 11px ;
    border-radius: 8px;
    border: 2px solid var(--danger-color) !important;
    font-family:"DM-Sans-Regular";
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    text-decoration:none !important;
}
.btn-primary-al:hover{
    background-color: var(--primary-color-hover);
    border: 2px solid var(--primary-color-hover) !important;
}
.btn-primary-al:focus{
    background-color: var(--primary-color-press);
    border: 2px solid var(--primary-color-press) !important;

}
.btn-secondary-al{
    background-color: transparent;
    color: var(--primary-color);
    padding: 10px 11px;
    border-radius: 8px;
    border: 2px solid var(--primary-color) !important;
    font-family:"DM-Sans-Regular";
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    text-decoration: none !important;
}
.btn-secondary-al:hover{
    border-color: var(--primary-color-hover);
}
.btn-secondary-al:focus{
    border-color: var(--primary-color-press);
}
.cta{
    color: var(--primary-color-hover);
    background-color: transparent !important;
    border:none  !important;
    font-family: 'DM-Sans-Regular';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
}
.cta:hover{
    color: var(--primary-color);
}
.cta:focus{
    color: var(--primary-press);
}