.container-al{
    padding: 67px 42px;

}
.tabs-container {
    display: flex;
  }
  
  .tab {
    padding: 15px;
    text-align: center;
    cursor: pointer;
    position: relative;
    font-size:18px;
    /* font-weight: 600; */
    font-family: 'DM-Sans-Medium';
    color: var(--typo-color)
  }
  
  .underline {
    height: 2px;
    width: 0;
    background-color: var(--primary-color); /* Set your desired underline color */
    position: absolute;
    bottom: 0;
    transition: width 0.3s ease;
  }
  
  .tab.active {
   border-bottom: 5px solid var(--primary-color);
   padding-bottom: 10px;
  }
  .priority-Faible{
    border-radius: 20px;
    background-color: #DFF5DC;
    padding: 10px;
    color:#5FCC4E;
    text-align: center;
    font-family: 'DM-Sans-Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .priority-Moyenne{
    border-radius: 20px;
    background-color: #FFE8D0;
    padding: 10px;
    color:#FF8A15;
    text-align: center;
    font-family: 'DM-Sans-Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .priority-Elevée{
    border-radius: 20px;
    background-color: #FCDBD4;
    padding: 10px;
    color:#F14B26;
    text-align: center;
    font-family: 'DM-Sans-Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  /* drag and drop style component */
  .dropzone {
    border: 2px dashed var(--primary-color);
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  .dropzone.active {
    border-color: var(--primary-color-press);
  }